import router from '@/router'
import { isUserLoggedIn } from '@/auth/utils'
import { jwtDecode } from 'jwt-decode'

export const defaultMenu = [
  {
    title: 'Inicial',
    route: 'adm',
    icon: 'HomeIcon',
  },
  {
    title: 'Cadastro',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'Tomador',
        route: 'CadastroTomador',
      },
      {
        title: 'Órgão',
        route: 'CadastroOrgao',
      },
      {
        title: 'Grupo Folha',
        route: 'CadastroGrupoFolha',
      },
    ],
  },
  {
    title: 'Simulador de Proposta',
    route: 'Proposta',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Contratos',
    route: 'Financeiro',
    icon: 'FileTextIcon',
  },
  {
    title: 'Financeiro',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'Pagamentos',
        route: 'FinanceiroPagamento',
      },
      {
        title: 'Remessa Boletos',
        route: 'FinanceiroRemessaBoleto',
      },
      {
        title: 'Lançamentos',
        route: 'FinanceiroLancamento',
      },
      {
        title: 'Arrecadação',
        route: 'FinanceiroArrecadacao',
      },
    ],
  },
  {
    title: 'Renegociação',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Dívidas',
        route: 'RenegociacaoDividas',
      },
      {
        title: 'Antecipação',
        route: 'RenegociacaoAntecipacao',
      },
    ],
  },
  {
    title: 'Relatórios',
    icon: 'BarChartIcon',
    children: [
      {
        title: 'Cadastro',
        route: 'RelatorioCadastro',
      },
      {
        title: 'Simulador de Proposta',
        route: 'RelatorioSimulador',
      },
      {
        title: 'Contratos',
        route: 'RelatorioContrato',
      },
      {
        title: 'Financeiro',
        route: 'RelatorioFinanceiro',
      },
      {
        title: 'Renegociação',
        route: 'RelatorioRenegociacao',
      },
    ],
  },
  {
    title: 'Dashboards',
    route: 'Dashboards',
    icon: 'PieChartIcon',
  },
  {
    title: 'Importar/Exportar',
    icon: 'HardDriveIcon',
    route: 'ImportarExportar',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Sistema',
        route: 'ConfiguracoesSistema',
      },
      {
        title: 'Grupos de Acesso',
        route: 'ConfiguracoesWorkflow',
      },
      {
        title: 'Usuário',
        route: 'CadastroUsuario',
      },
      {
        title: 'Unidade Gestora',
        route: 'CadastroUnidadeGestora',
      },
      {
        title: 'Modelos Documentos',
        route: 'ConfiguracoesModelos',
      },
      {
        title: 'Gerenciamento de LOG',
        route: 'ConfiguracoesSistemaLog',
      },
      {
        title: 'Assinatura Digital',
        route: 'AssinaturaDigital',
      },
    ],
  },
]

const menuWithRules = verifyPermissions(defaultMenu)
/**
 * Verifica as permissões do usuário para cada item do menu.
 * Se o usuário estiver logado, verifica se ele tem permissão para acessar cada página.
 * Se o usuário não tiver permissão para acessar uma página, desabilita-a.
 *
 * @param {Array} menuItems - Os itens do menu.
 * @return {Array} Os itens do menu atualizados com as permissões.
 */
export function verifyPermissions() {
  let menuItems = defaultMenu
  const isLoggedIn = isUserLoggedIn()

  const listaDePaginasSemProibicao = ['adm', 'AssinaturaDigital']
  const userData = localStorage.getItem('userData')
  let grupoAcesso = null

  if (userData) {
    grupoAcesso = jwtDecode(userData)['userData']['GrupoAcesso']
  }

  menuItems = menuItems.map((menuItem) => {
    return { ...menuItem, disabled: false, hidden: false }
  })

  if (isLoggedIn) {
    if (userData.role !== 'master' && grupoAcesso) {
      menuItems = menuItems
        .map((menuItem) => {
          if (menuItem.children) {
            menuItem.children = menuItem.children.map((child) => {
              if (listaDePaginasSemProibicao.includes(child.route)) {
                return { ...child, disabled: false, hidden: false }
              }

              return { ...child, ...setVisibility(child, grupoAcesso[child.route]) }
            })

            // Verifica se todos os filhos estão desabilitados/ocultos
            const allChildrenDisabled = menuItem.children.every((child) => child.disabled && child.hidden)
            if (allChildrenDisabled) {
              return { ...menuItem, disabled: true, hidden: true }
            }

            menuItem.children = menuItem.children.filter((child) => !child.hidden)
          }

          if (listaDePaginasSemProibicao.includes(menuItem.route)) {
            return { ...menuItem, disabled: false, hidden: false }
          }

          if (menuItem.route) {
            return { ...menuItem, ...setVisibility(menuItem, grupoAcesso[menuItem.route]) }
          } else {
            return { ...menuItem, disabled: false, hidden: false }
          }
        })
        .filter((menuItem) => !menuItem.hidden)
      return menuItems
    } else if (userData.role === 'master') {
      return menuItems
    }
  }
  return menuItems
}

function setVisibility(item, isAccessible) {
  if (!isAccessible) {
    return { disabled: true, hidden: true }
  }
  return { disabled: false, hidden: false }
}

export default menuWithRules
