<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded h-auto">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-column">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto w-100 justify-content-between">
            <b-link class="nav-link modern-nav-toggle d-flex justify-content-end" style="margin: 1vh 0px">
              <span>
                <feather-icon icon="XIcon" size="18" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
                <feather-icon
                  :icon="collapseTogglerIconFeather"
                  size="18"
                  class="d-none d-xl-block collapse-toggle-icon"
                  @click="toggleCollapsed"
                />
              </span>
            </b-link>
            <b-link style="margin-top: 1vh" class="navbar-brand" :to="isVerticalMenuCollapsed ? '/adm' : '#'">
              <span>
                <div v-if="!loadingMenu">
                  <b-img
                    v-if="isVerticalMenuCollapsed && !isMouseHovered"
                    id="logoTipo"
                    :src="images ? (images.logo ? images.logo : appLogoImage) : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  />
                  <b-img
                    v-else
                    id="logoMarca"
                    :src="images ? (images.logoMarca ? images.logoMarca : appLogoImage) : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  />
                </div>
              </span>
            </b-link>
          </li>
        </ul>

        <ul class="flex-row" v-if="!apagaSelector">
          <li class="nav-item brand-text">
            <div style="margin-left: -8px">
              <instituto-selecionado />
            </div>
          </li>
        </ul>
      </slot>
    </div>

    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom"></div>
    <div style="margin-bottom: 0.5vh"></div>
    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items v-if="navMenuItems" :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>

    <!-- /main menu content-->
  </div>
</template>

<script>
  import navMenuItems from '@/navigation/vertical'
  import { verifyPermissions } from '@/navigation/vertical'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import { provide, computed, ref } from '@vue/composition-api'
  import useAppConfig from '@core/app-config/useAppConfig'
  import { $themeConfig } from '@themeConfig'
  import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
  import useVerticalNavMenu from './useVerticalNavMenu'
  import InstitutoSelecionado from '@/@core/layouts/components/app-navbar/components/InstitutoSelecionado.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      VuePerfectScrollbar,
      VerticalNavMenuItems,
      InstitutoSelecionado,
      verifyPermissions,
      navMenuItems,
    },

    props: {
      isVerticalMenuActive: {
        type: Boolean,
        required: true,
      },
      toggleVerticalMenuActive: {
        type: Function,
        required: true,
        aberto: true,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        images: JSON.parse(localStorage.getItem('images')),
        apagaSelector: false,
        verifyPermissions,
        navMenuItems,
        verificaAdmin: false,
        loadingMenu: false,
      }
    },

    async mounted() {
      if (this.userData.role == 'master') {
        this.apagaSelector = false
      } else if (this.userData.role == 'admin' && this.userData.institutosVinculados?.length > 1) {
        this.apagaSelector = false
      } else {
        this.apagaSelector = true
      }

      this.atualizarItemsMenu()

      this.$root.$on('atualizarItemsMenu', () => {
        this.atualizarItemsMenu()
      })

      this.$root.$on('loadingMenu', (item) => {
        this.loadingMenu = item
      })

      this.$root.$on('atualizarLogoMenu', () => {
        this.images = JSON.parse(localStorage.getItem('images'))
      })
    },
    setup(props) {
      const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } =
        useVerticalNavMenu(props)

      const { skin } = useAppConfig()

      // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
      const shallShadowBottom = ref(false)

      provide('isMouseHovered', isMouseHovered)

      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

      // App Name
      const { appName, appLogoImage } = $themeConfig.app

      return {
        navMenuItems,
        perfectScrollbarSettings,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        isMouseHovered,
        updateMouseHovered,
        collapseTogglerIconFeather,

        // Shadow Bottom
        shallShadowBottom,

        // Skin
        skin,

        // App Name
        appName,
        appLogoImage,
      }
    },
    methods: {
      atualizarItemsMenu() {
        this.navMenuItems = verifyPermissions(this.navMenuItems)
      },
    },
  }
</script>
<style lang="scss">
  @import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
  #logoTipo {
    max-width: 40px !important;
    max-height: 40px !important;
  }

  #logoMarca {
    max-width: 232px !important;
    max-height: 104px !important;
  }
</style>
