<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="totalNotification" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center">
        <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
        <a>
          <b-icon
            v-b-tooltip.hover.bottom="'Limpar as notificações'"
            :icon="isHovered ? 'trash-fill' : 'trash'"
            :class="['trash-icon', { hovered: isHovered }]"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
            @click="deleteNotification"
          >
            Limpar as notificações
          </b-icon>
        </a>
      </div>
    </li>

    <template v-if="isBusy">
      <div class="loading-indicator align-middle">
        <b-spinner small label="Small Spinner" class="align-middle mr-50"></b-spinner>
        <span>Carregando...</span>
      </div>
    </template>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-else
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="item in notificacoes" :key="item.title">
        <b-media>
          <h6 class="text-middle d-flex justify-content-between align-items-center">
            <p class="font-weight-bolder media-heading">{{ item.title }}</p>
            <b-badge class="alert-danger" v-if="item.count">{{ item.count }}</b-badge>
          </h6>
          <small class="font-weight-bold media-heading">{{ item.text }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
  import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      BNavItemDropdown,
      BBadge,
      BMedia,
      BLink,
      BAvatar,
      VuePerfectScrollbar,
      BButton,
      BFormCheckbox,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        notificacoes: [],
        totalNotification: '',
        isHovered: false,
        intervalId: null,
      }
    },
    mounted() {
      this.carregarGrid()

      this.intervalId = setInterval(() => {
        this.carregarGrid()
      }, 30000)
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    },
    methods: {
      carregarGrid() {
        if (this.userData.id) {
          useJwt
            .get(`utils/buscarNotificacoes/${this.userData.id}`)
            .then((response) => {
              const notificacoes = response.data
              const novaPropostaNotificacoes = notificacoes.filter((n) => n.title === 'Nova proposta Totem!')
              const outrasNotificacoes = notificacoes.filter((n) => n.title !== 'Nova proposta Totem!')

              if (novaPropostaNotificacoes.length > 0) {
                // Adiciona a notificação agrupada com o contador e o texto concatenado
                outrasNotificacoes.push({
                  title: 'Atenção! Uma nova simulação criada no totem!',
                  count: novaPropostaNotificacoes.length,
                  text: 'Verifique os detalhes em Simulador de Proposta',
                })
              }

              this.notificacoes = outrasNotificacoes
              this.totalNotification = outrasNotificacoes.length
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      deleteNotification() {
        this.isBusy = true
        useJwt
          .delete(`utils/deleteNotificacoes`, this.userData.id)
          .then((response) => {
            this.notificacoes = []
            this.totalNotification = 0
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.carregarGrid()
            this.isBusy = false
          })
      },
    },
    setup() {
      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      return {
        perfectScrollbarSettings,
      }
    },
  }
</script>

<style>
  .loading-indicator {
    align-items: center;
    text-align: center;
    padding: 30px;
  }
  .trash-icon {
    font-size: 1.6rem;
    color: #000;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  .trash-icon:hover {
    transform: scale(1.2);
    color: red;
  }
</style>
